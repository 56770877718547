<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-layout>
      <v-flex> </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 md4>
        <v-layout
          wrap
          py-2
          v-if="caseDetails && Object.keys(caseDetails).length > 0"
        >
          <v-flex
            md12
            v-if="
              caseDetails &&
              caseDetails.mainMergeReportImages &&
              caseDetails.mainMergeReportImages.length > 0
            "
          >
            <OwlCarousel
              :nav="false"
              :dots="true"
              :responsive="owlResponsive"
              :autoplay="true"
              :autoplayHoverPause="true"
              :autoplayTimeout="5000"
            >
              <template v-for="(item, i) in caseDetails.mainMergeReportImages">
                <CarouselImage
                  :key="i"
                  v-if="item.hasImage"
                  v-bind:storage="item"
                />
              </template>
            </OwlCarousel>
          </v-flex>
          <v-flex v-if="caseDetails.mainMergeReportImages">
            <v-img
              v-if="caseDetails.mainMergeReportImages.length == 0"
              height="120px"
              cover
              alt="Wildwatch"
              :src="caseDetails.image"
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-flex>
          <v-flex md12>
            <v-card class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex xs4 sm4 md4>
                      <v-btn
                        x-small
                        depressed
                        class="py-2"
                        :outlined="
                          caseDetails.status == 'Pending' ? true : false
                        "
                        :ripple="false"
                        :color="
                          caseDetails.status == 'Pending'
                            ? '#deb710'
                            : '#F5F5F5'
                        "
                        style="letter-spacing: 0px"
                        ><span
                          style="
                            font-family: sofiaProRegular;
                            color: #000;
                            font-size: 8px;
                          "
                          >RESPONSE NEEDED
                        </span></v-btn
                      >
                    </v-flex>
                    <v-flex v-if="caseDetails.scenarioId" xs4 sm4 md4>
                      <v-btn
                        x-small
                        depressed
                        class="py-2"
                        :outlined="
                          caseDetails.status == 'Pending' ? true : false
                        "
                        :ripple="false"
                        :color="
                          caseDetails.status == 'Pending'
                            ? '#deb710'
                            : '#F5F5F5'
                        "
                        style="letter-spacing: 0px"
                        ><span
                          style="
                            font-family: sofiaProRegular;
                            color: #000;
                            font-size: 8px;
                          "
                          >{{ caseDetails.scenarioId.name }}
                        </span></v-btn
                      >
                    </v-flex>
                    <v-flex xs4 sm4 md4 text-end>
                      <span
                        class="text-uppercase"
                        style="
                          font-family: sofiaProRegular;
                          color: #deb710;
                          font-size: 8px;
                        "
                      >
                        {{ caseDetails.statuscopy }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- hii{{ socketType }}<br />
              reportid{{ caseDetails._id }}<br />
              {{socketData}}
              socketid{{ socketData._id }}<br />
              id{{ $route.query.id }} -->
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex
                      v-if="caseDetails.isAssignedToMe"
                      xs4
                      sm3
                      md3
                      text-left
                    >
                      <span
                        class="text-uppercase"
                        style="
                          font-family: sofiaProRegular;
                          color: #deb710;
                          font-size: 8px;
                        "
                      >
                        Self Assigned</span
                      >
                    </v-flex>
                    <v-flex
                      v-if="caseDetails.isTransferred"
                      text-right
                      xs4
                      sm3
                      md3
                      text-left
                    >
                      <span
                        class="text-uppercase"
                        style="
                          font-family: sofiaProRegular;
                          color: #deb710;
                          font-size: 8px;
                        "
                      >
                        Transffered</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12>
                  <span v-if="caseDetails && caseDetails.animalId">
                    <span v-if="caseDetails.animalId" class="itemHeading">
                      {{ caseDetails.animalId.name }}
                    </span>
                  </span>
                  <span v-else class="itemHeading">Unknown Animal</span>
                  <span
                    class="float-right"
                    v-if="caseDetails.update_date"
                    style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 10px;
                    "
                  >
                    <!-- <timeago
                      :datetime="caseDetails.create_date"
                      :auto-update="60"
                    ></timeago> -->
                    {{ timeSince(caseDetails.update_date) }}
                  </span>

                  <!-- <p
                    style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 10px;
                    "
                  >
                    15.6 km Away
                  </p> -->
                </v-flex>
                <!-- Voice Recording -->
                <!-- <v-flex xs12>
                  <v-layout wrap pt-2 v-if="caseDetails.voice">
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D"> mdi-microphone</v-icon>
                    </v-flex>

                    <v-flex xs10>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Voice Recording</span
                      ><br />
                      <span
                        v-if="caseDetails.voice"
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <div>
                          <audio muted controls>
                            <source
                              :src="mediaURL + caseDetails.voice" type="audio/mp4"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
                <v-flex xs12 pt-2>
                  <template wrap>
                    <Actions
                      :sideNav="sideNav"
                      :caseDetails="caseDetails"
                      :createDate="timeSince(caseDetails.update_date)"
                      @stepper="winStepper"
                    />
                  </template>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap>
                <v-flex xs12>
                  <!-- <span class="itemHeading">Conflict Information</span> -->
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 lg6
                          ><span class="itemHeading" text-left
                            >Conflict Information</span
                          ></v-flex
                        >
                        <!-- <v-flex xs12 lg6 text-right
                          ><span
                            style="
                              font-family: sofiaProRegular;
                              color: #deb710;
                              font-size: 10px;
                              cursor: pointer;
                            "
                            @click="openAssignDialog()"
                            >Assign To</span
                          ></v-flex
                        > -->
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pt-2>
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D"> mdi-map-marker </v-icon>
                    </v-flex>

                    <v-flex xs10>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Location</span
                      ><br />
                      <span
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <span v-if="caseDetails.locationname">{{
                          caseDetails.locationname
                        }}</span>
                        <span v-else>Unknown Location</span>
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pt-2>
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D"> mdi-details </v-icon>
                    </v-flex>

                    <v-flex xs10>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: sofiaProLight;
                              font-size: 13px;
                              color: #4d4d4d;
                            "
                            >Sighted By</span
                          ><br />
                          <span
                            style="
                              font-family: sofiaProRegular;
                              font-size: 12px;
                              color: #000;
                              line-height: 0.5;
                            "
                          >
                            <span
                              v-if="
                                caseDetails &&
                                caseDetails.addedBy &&
                                caseDetails.addedBy.role
                              "
                            >
                              <span v-if="caseDetails.addedBy.role === 'User'">
                                <span
                                  >{{ caseDetails.addedBy.userId.name }}-{{
                                    caseDetails.addedBy.userId.phone
                                  }}</span
                                >
                              </span>
                              <span
                                v-if="caseDetails.addedBy.role === 'MASTER'"
                              >
                                <span>{{
                                  caseDetails.addedBy.admin.masterName
                                }}</span>
                              </span>
                              <span
                                v-if="
                                  caseDetails.addedBy.role === 'EmergencyUser'
                                "
                              >
                                <span v-if="caseDetails.addedBy.userId">{{
                                  caseDetails.addedBy.userId.name
                                }}</span>
                              </span>
                              <span v-if="caseDetails.addedBy.role === 'RANGE'">
                                <span>{{
                                  caseDetails.addedBy.range.accessCredentials
                                    .username
                                }}</span>
                              </span>
                              <span
                                v-if="caseDetails.addedBy.role === 'DIVISION'"
                              >
                                <span>{{
                                  caseDetails.addedBy.division.accessCredentials
                                    .username
                                }}</span>
                              </span>
                              <span v-if="caseDetails.addedBy.role === 'RRT'">
                                <span v-if="caseDetails.addedBy.userId.name">{{
                                  caseDetails.addedBy.userId.name
                                }}</span>
                              </span>
                              <span
                                v-if="
                                  caseDetails.addedBy.role ===
                                    'EmergencyUser' &&
                                  !caseDetails.addedBy.userId
                                "
                              >
                                <span>Emergency User</span>
                              </span>
                              <span v-if="!caseDetails.addedBy.role"
                                >UnKnown
                              </span>
                            </span>
                            <span v-else>Unknown</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pt-2>
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D"> mdi-alert-circle-outline</v-icon>
                    </v-flex>

                    <v-flex xs10>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Threat Level</span
                      ><br />
                      <span
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <span v-if="caseDetails && caseDetails.threatlevel">{{
                          caseDetails.threatlevel
                        }}</span>
                        <span v-else>Unknown Threat Level</span>
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    wrap
                    pt-2
                    v-if="
                      caseDetails.isHandovered &&
                      Object.keys(caseDetails.handoveredFrom).length > 0
                    "
                  >
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D">
                        mdi-file-arrow-left-right</v-icon
                      >
                    </v-flex>

                    <v-flex xs10>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Handovered From</span
                      ><br />
                      <span
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <span
                          v-if="
                            caseDetails.handoveredFrom.handoverplatform ===
                            'RANGE'
                          "
                        >
                          <span
                            >{{
                              caseDetails.handoveredFrom.rangeId
                                .accessCredentials.username
                            }}
                            (RANGE)</span
                          >
                        </span>
                        <span
                          v-if="
                            caseDetails.handoveredFrom.handoverplatform ===
                            'DIVISION'
                          "
                        >
                          <span
                            >{{
                              caseDetails.handoveredFrom.divisionId
                                .accessCredentials.username
                            }}
                            (DIVISION)</span
                          >
                        </span>
                        <span
                          v-if="
                            caseDetails.handoveredFrom.handoverplatform ===
                            'RRT'
                          "
                        >
                          <span v-if="caseDetails.handoveredFrom.RRT.name"
                            >{{
                              caseDetails.handoveredFrom.RRT.name
                            }}
                            (RRT)</span
                          >
                        </span>
                        <span
                          v-if="!caseDetails.handoveredFrom.handoverplatform"
                          >UnKnown
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pt-2 v-if="caseDetails.voice">
                    <v-flex xs2 sm1 md1>
                      <v-icon color="#4D4D4D"> mdi-microphone</v-icon>
                    </v-flex>

                    <v-flex xs10>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Voice Recording</span
                      ><br />
                      <span
                        v-if="caseDetails.voice"
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <div>
                          <audio muted controls>
                            <source
                              :src="mediaURL + caseDetails.voice"
                              type="audio/mp4"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 pt-4>
            <ActionDetails :caseDetails="caseDetails" @stepper="winStepper" />
          </v-flex>

          <v-flex
            v-if="
              caseDetails.status != 'Captured' &&
              caseDetails.status != 'Released' &&
              caseDetails.status != 'Assigned'
            "
            md12
            text-center
            pt-2
          >
            <v-card
              class="pa-3"
              v-if="caseDetails.mergeSuggestion_reportsCount > 0"
            >
              <span
                style="
                  color: #ff6907;
                  font-family: sofiaProRegular;
                  font-size: 14px;
                "
                ><span
                  >{{ caseDetails.mergeSuggestion_reportsCount }} More
                  Case</span
                >
              </span>
              <span
                style="
                  color: #626262;
                  font-family: sofiaProRegular;
                  font-size: 14px;
                "
              >
                Reported From Same Location</span
              ><br />
              <span
                v-if="caseDetails.locationname"
                style="
                  color: #000;
                  font-family: sofiaProMedium;
                  font-size: 14px;
                "
                >{{ caseDetails.locationname }}</span
              >
              <v-layout
                wrap
                justify-center
                v-if="caseDetails.mergeSuggestion_reportsCount > 0"
              >
                <v-flex md6 xs8 sm4 align-self-center pa-4 text-center>
                  <v-card
                    @click="
                      $router.push(
                        '/Admin/mergeCases/mergeCaseSuggestion?id=' +
                          caseDetails._id
                      )
                    "
                    tile
                    class="pa-2"
                  >
                    <v-icon color="#E34F00"> mdi-call-merge </v-icon>
                    <span
                      style="
                        font-family: sofiaProSemiBold;
                        color: #000;
                        font-size: 14px;
                      "
                    >
                      Merge Cases
                    </span>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md8 pl-2 pt-1>
        <div style="position: fixed" id="map"></div>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <Users
          :sideNav="sideNav"
          :users="users"
          :avatars="avatars"
          :caseDetails="caseDetails"
          :createDate="timeSince(caseDetails.update_date)"
          @stepper="winStepper"
        />
      </v-flex>
    </v-layout>
    <v-dialog v-model="assignDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Assign To</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 lg6>
                  <span>Division</span>
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    v-model="divisionname"
                    placeholder="Select"
                    :items="divisionlist"
                    item-text="divisionName"
                    item-value="_id"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 lg6 pl-2>
                  <span>Range</span>
                  <v-autocomplete
                    outlined
                    dense
                    placeholder="Select"
                    hide-details
                    v-model="rangename"
                    item-text="rangeName"
                    item-value="_id"
                    :items="rangelist"
                    :disabled="divisionname ? false : true"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="closeAssignDialog"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            class="itemValue"
          >
            <span style="color: #fff">Assign</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
var marker;
var marker1;
import CarouselImage from "@/components/Cases/carouselImage";
import ActionDetails from "@/components/Cases/ActionDetails/actionDetails";
import Users from "@/components/Cases/users";
import Actions from "@/components/Cases/Actions/actions";
// import Socket from "./../../../Sockets/socket";

export default {
  components: {
    Users,
    Actions,
    CarouselImage,
    ActionDetails,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      map: "",
      map2: "",
      caseDetails: [],
      ServerError: false,
      users: {},
      // avatars: [],
      newDate: null,
      caseImage: null,
      dataLength: null,
      location: null,
      newArray: [],
      mapData: [],
      statusofreport: this.$route.query.status,
      photo: "",
      divisionname: "",
      divisionlist: [],
      rangename: "",
      rangelist: [],
      mergeImages: [],
      kmRange: null,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },
      tempUsers: [
        {
          fullName: "Anu Joseph",
          location: [76.79026074707508, 10.562300945210879],
          address: "test location",
        },
      ],
      socketType: null,
      socketData: {},
      assignDialog: false,
      selectedOption1: null,
      selectedOption2: null,
      selectedOption3: null,
      options1: ["Option 1A", "Option 1B", "Option 1C"],
      options2: ["Option 2A", "Option 2B", "Option 2C"],
      options3: ["Option 3A", "Option 3B", "Option 3C"],
    };
  },
  mounted() {
    this.getData();
    // this.getUsers()
    this.getMerge();
    // this.getSocketData();
    this.getDivisionData();
    this.getRangeData();
    // this.initMap();
  },
  computed: {
    caseStatus() {
      return this.caseDetails.status;
    },
    avatars() {
      var avatars = [];
      if (this.users) {
        for (var i = 0; i < this.users.offlineUsersCount; i++) {
          avatars.push(this.mediaURL + this.users.offlineUsers[i].photo);
        }
      }

      return avatars;
    },
  },
  watch: {
    kmRange() {
      this.getUsers();
    },
    divisionname() {
      this.getRangeData();
    },
    socketType() {
      if (
        this.socketType == "reportLocationUpdate" ||
        this.socketType == "reportTransferRequest" ||
        this.socketType == "reportClose" ||
        this.socketType == "reportAssign" ||
        this.socketType == "reportRelease" ||
        this.socketType == "reportCapture" ||
        this.socketType == "reportResolve" ||
        this.socketType == "newMergeSuggestion"
        // || this.socketType=="newConflict"
      ) {
        // if (this.caseDetails._id == this.socketData._id) {
        //   this.caseDetails = this.socketData;
        //   if (this.caseDetails.reportImages.length > 0)
        //     this.caseDetails.image =
        //       this.mediaURL + this.caseDetails.reportImages[0];
        //   else this.caseDetails.image = this.mediaURL + "noimage.jpg";
        //   if (!this.caseDetails.userId.fullName)
        //     this.caseDetails.userId.fullName = "Unknown";
        //   if (!this.caseDetails.address)
        //     this.caseDetails.address = "Unknown Address";
        //   this.caseDetails.mainMergeReportImages = this.socketData.mainMergeReportImages;
        //   // this.getData();
        // }
      }
      // else   if(this.socketType == "reportTransferRequest")
      // {
      //  this.caseDetails=this.socketData
      // }
      //  else   if(this.socketType == "reportClose")
      // {
      //  this.caseDetails=this.socketData
      // }
    },
  },

  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },

  methods: {
    getSocketData() {
      Socket.dataFunction(this); // <---read data
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangelist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openAssignDialog() {
      this.assignDialog = true;
    },
    closeAssignDialog() {
      this.assignDialog = false;
    },
    winStepper(window_data) {
      if (window_data.type == "reportActions") {
        this.getData();
      } else if (window_data.type == "sideNav")
        this.sideNav = window_data.sideNav;
      if (window_data.type == "sendToPublic") {
        this.sideNav = window_data.sideNav;
        this.getData();
      } else if (window_data.type == "usersNearBy") {
        {
          this.kmRange = window_data.kmRange;
        }
      }
    },
    getUsers() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/report/users/nearby",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: { reportId: this.$route.query.id, kmRange: this.kmRange },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.users = response.data;
            // setTimeout(function () {}, 50000);

            // for (var i = 0; i < this.users.offlineUsers.length; i++) {
            //   if (!this.users.offlineUsers[i].fullName)
            //     this.users.offlineUsers[i].fullName = "Unknown";
            //   if (!this.users.offlineUsers[i].address)
            //     this.users.offlineUsers[i].address = "Unknown Address";
            // }
            // for (var j = 0; j < this.users.activeUsers.length; j++) {
            //   if (!this.users.activeUsers[j].fullName)
            //     this.users.activeUsers[j].fullName = "Unknown";
            //   if (!this.users.activeUsers[j].address)
            //     this.users.activeUsers[j].address = "Unknown Address";
            // }
            // this.initMap();
          }
        })
        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
    getMerge() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/report/merge/suggestion",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          reportId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dataLength = response.data.length;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
    // initializeMap() {
    //   if (!this.location || this.location.length < 2) {
    //     console.error("Invalid location data");
    //     return;
    //   }

    //   const latLng = { lat: this.location[1], lng: this.location[0] };
    //   this.map = new google.maps.Map(document.getElementById("map"), {
    //     center: latLng,
    //     zoom: 10,
    //   });

    //   new google.maps.Marker({
    //     position: latLng,
    //     map: this.map,
    //   });
    // },
    // initializeMap() {
    //   // Check if the main location is valid
    //   if (!this.location || this.location.length < 2) {
    //     console.error("Invalid main location data");
    //     return;
    //   }

    //   // Initialize the map centered on the first location (this.location)
    //   const latLng = { lat: this.location[1], lng: this.location[0] };
    //   this.map = new google.maps.Map(document.getElementById("map"), {
    //     center: latLng,
    //     zoom: 10,
    //   });

    //   // Create an InfoWindow instance
    //   const infoWindow = new google.maps.InfoWindow();

    //   // Add marker for the main (reported) location with a custom icon
    //   const mainMarker = new google.maps.Marker({
    //     position: latLng,
    //     map: this.map,
    //     title: "Reported Location",
    //     icon: require("@/assets/images/reportloc.png"), // Custom icon for reported location
    //   });

    //   // Add click event listener to show the title when clicking the marker
    //   mainMarker.addListener("click", () => {
    //     const content = `
    //   <div style="width: 200px; height: 100px;">
    //     <h3 style="margin: 0;">Reported Location</h3>
    //     <p>This is the main location where the event was reported.</p>
    //   </div>`;
    //     infoWindow.setContent(content);
    //     infoWindow.open(this.map, mainMarker);
    //   });

    //   // Add marker for releasedLocation with a different custom icon
    //   if (this.releasedLocation && this.releasedLocation.length >= 2) {
    //     const releasedLatLng = {
    //       lat: this.releasedLocation[1],
    //       lng: this.releasedLocation[0],
    //     };
    //     const releasedMarker = new google.maps.Marker({
    //       position: releasedLatLng,
    //       map: this.map,
    //       title: "Released Location",
    //       icon: require("@/assets/images/releaseloc.png"), // Custom icon for released location
    //     });

    //     releasedMarker.addListener("click", () => {
    //       const content = `
    //     <div style="width: 200px; height: 100px;">
    //       <h3 style="margin: 0;">Released Location</h3>
    //       <p>This is where the animal was released.</p>
    //     </div>`;
    //       infoWindow.setContent(content);
    //       infoWindow.open(this.map, releasedMarker);
    //     });
    //   } else {
    //     console.warn("Invalid released location data");
    //   }

    //   // Add marker for resolveLocation with another different custom icon
    //   if (this.resolveLocation && this.resolveLocation.length >= 2) {
    //     const resolveLatLng = {
    //       lat: this.resolveLocation[1],
    //       lng: this.resolveLocation[0],
    //     };
    //     const resolveMarker = new google.maps.Marker({
    //       position: resolveLatLng,
    //       map: this.map,
    //       title: "Resolved Location",
    //       icon: require("@/assets/images/resolveloc.png"), // Custom icon for resolved location
    //     });

    //     resolveMarker.addListener("click", () => {
    //       const content = `
    //     <div style="width: 200px; height: 100px;">
    //       <h3 style="margin: 0;">Resolved Location</h3>
    //       <p>This is where the issue was resolved.</p>
    //     </div>`;
    //       infoWindow.setContent(content);
    //       infoWindow.open(this.map, resolveMarker);
    //     });
    //   } else {
    //     console.warn("Invalid resolve location data");
    //   }
    // },
    initializeMap() {
      // Validate location data
      const isValidLocation = (location) =>
        Array.isArray(location) &&
        location.length >= 2 &&
        !isNaN(location[0]) &&
        !isNaN(location[1]);

      // Determine the center of the map
      let initialLatLng = { lat: 10.8505, lng: 76.2711 }; // Default center (Kerala, India)
      if (isValidLocation(this.location)) {
        initialLatLng = { lat: this.location[1], lng: this.location[0] };
      }

      // Initialize the map
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: initialLatLng,
        zoom: 10,
      });

      // Create an InfoWindow instance
      const infoWindow = new google.maps.InfoWindow();

      // Function to add a marker
      const addMarker = (location, title, icon, content) => {
        const marker = new google.maps.Marker({
          position: { lat: location[1], lng: location[0] },
          map: this.map,
          title,
          icon: require(`@/assets/images/${icon}`),
        });

        marker.addListener("click", () => {
          infoWindow.setContent(content);
          infoWindow.open(this.map, marker);
        });
      };

      // Add markers for valid locations
      if (isValidLocation(this.location)) {
        addMarker(
          this.location,
          "Reported Location",
          "reportloc.png",
          `<div style="width: 200px; height: 100px;">
        <h3 style="margin: 0;">Reported Location</h3>
        <p>This is the main location where the event was reported.</p>
      </div>`
        );
      }

      if (isValidLocation(this.releasedLocation)) {
        addMarker(
          this.releasedLocation,
          "Released Location",
          "releaseloc.png",
          `<div style="width: 200px; height: 100px;">
        <h3 style="margin: 0;">Released Location</h3>
        <p>This is where the animal was released.</p>
      </div>`
        );
      }

      if (isValidLocation(this.resolveLocation)) {
        addMarker(
          this.resolveLocation,
          "Resolved Location",
          "resolveloc.png",
          `<div style="width: 200px; height: 100px;">
        <h3 style="margin: 0;">Resolved Location</h3>
        <p>This is where the issue was resolved.</p>
      </div>`
        );
      }
    },

    getData() {
      this.appLoading = true;
      // console.log("apploadingggggggggg", this.appLoading);
      axios({
        method: "POST",
        url: "/report/view",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          status: this.statusofreport,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // var caseDetails = {};
            this.caseDetails = response.data.data;
            this.location = response.data.data.location;
            if (response.data.data.releaseDetails) {
              this.releasedLocation =
                response.data.data.releaseDetails.releasedLocation;
            }
            if (response.data.data.resolveDetails) {
              this.resolveLocation = response.data.data.resolveDetails.location;
            }
            this.initializeMap();
            // for (var i = 0; i < caseDetails.mainMergeReportImages.length; i++) {
            //   this.mergeImages.push({
            //     hasImage: true,
            //     image: caseDetails.mainMergeReportImages[i],
            //   });
            // }
            // if (caseDetails.reportImages.length > 0)
            //   caseDetails.image = this.mediaURL + caseDetails.reportImages[0];
            // else caseDetails.image = this.mediaURL + "noimage.jpg";
            // if (!caseDetails.userId.fullName)
            //   caseDetails.userId.fullName = "Unknown";
            // if (!caseDetails.address) caseDetails.address = "Unknown Address";
            // this.caseDetails = caseDetails;

            // this.getUsers();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    //   initMap() {
    //   var vm = this;
    //   var mapCanvas = document.getElementById("map");
    //   var mapOptions = {
    //     center: new google.maps.LatLng(
    //       this.caseDetails[0].location[1],
    //       this.caseDetails[0].location[0]
    //     ),
    //     zoom: 12,
    //     mapTypeId: google.maps.MapTypeId.TERRAIN,
    //   };
    //   var icon = {
    //     url: this.mediaURL + "footPrint.png",
    //     scaledSize: new google.maps.Size(25, 32),
    //     origin: new google.maps.Point(0, 0),
    //     anchor: new google.maps.Point(0, 0),
    //   };
    //   vm.map = new google.maps.Map(mapCanvas, mapOptions);

    //   this.caseDetails.forEach(function (item) {
    //     var lat = caseDetails.location[1];
    //     var lon = caseDetails.location[0];
    //     var latlngset = new google.maps.LatLng(lat, lon);
    //     var marker = new google.maps.Marker({
    //       map: vm.map,
    //       position: latlngset,
    //       icon: icon,
    //     });

    //     // Create content for each marker
    //     const contentString = `
    //       <div style="font-family: sofiaProRegular;">
    //         <h3>${caseDetails.animalId.name}</h3>
    //         <p><strong>Location Name:</strong> ${caseDetails.locationname || "Unknown"}</p>
    //         <p><strong>Scenario:</strong> ${caseDetails.scenarioId ? item.scenarioId.name : "Not specified"}</p>
    //         <p><strong>Reported Date & Time:</strong> ${caseDetails.reportTime ? vm.formatReportTime(item.reportTime) : "Not reported"}</p>
    //       </div>
    //     `;

    //     // Attach click event to marker
    //     marker.addListener("click", function () {
    //       var infowindow = new google.maps.InfoWindow({
    //         content: contentString,
    //       });
    //       infowindow.open(vm.map, marker);
    //     });
    //   });
    // },
  },
};
</script>
<style>
.gm-style-iw-d {
  overflow: hidden !important;
}
</style>